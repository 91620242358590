.current, .current:hover {
    text-decoration: none;
    cursor: default;
}

.nav-item > .current:hover::after {
    content: " (you are here)";
}

@font-face {
    font-family: DejaVuSansMono;
    src: url('/assets/fonts/DejaVuSansMono.ttf');
}

body {
    margin-top: 3em;
    font-family: 'DejaVuSansMono', monospace;
    background: #0B161D;
    color: #55E439;
}

ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

ul > li > a, ul > li > a:hover, ul > li > a:visited, ul > li > a:active {
    color: #55E439;
}

h1, h2, h3, h4 {
    color: #FFAA00;
    padding: 0.2em 0;
}
.intro {
    color: #FF3A83;
    margin-bottom: 2em;
}

code {
    color: #FF3A83;
}

.intro > a {
    text-decoration: underline;
}

pre.ascii-art {
    overflow: hidden;
}

.ascii-art {
    color: #1E9AE0;
    font-family: 'DejaVuSansMono', monospace;
}

.figure-caption {
    color: #1E9AE0;
    text-align: center;
    max-width: 75%;
}

.figure {
    display: block;
}

.figure-img, .figure-caption {
    display: block;
    margin: auto;
}

.container-fluid {
    margin-bottom: 3em;
}

.post-categories {
    font-size: 80%;
}

.nav-item > a:hover::after {
    content: " ->";
}

ul.nav {
    padding: 2em 0 0 1em;
}

.homepage-post {
    padding-bottom: 2em;
}

.post-navigation {
    padding-top: 2em;
    text-align: center;
}

.post-navigation > .next {
    float: right;
}

.post-navigation > .prev {
    float: left;
}

.post-navigation > .home {
    margin: 0 auto;
    text-align: center;
}

.navbar-toggler-icon {
    color: white;
}
/*
#mobile-nav {
    display: none;
}

#mobile-nav.active {
    display: block;
}
*/


#nav-toggle {
    float: right;
    height: 40px;
}

@media (max-width: 768px) {
    .ascii-art {
        font-size: 50%;
    }
}

@media (min-width: 768px) {
    #mobile-nav {
        display: none !important;
    }
}